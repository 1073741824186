/* eslint-disable import/prefer-default-export */

/**
 * Parses the URL path and restricts it to the current origin.
 * Returns a sanitized path.
 * Returns null if no path is provided or if parsing fails.
 * @param {string} path
 * @return {string|null}
 */
export const sanitizeRedirectPath = (path) => {
  if (!path) {
    return null;
  }

  try {
    return new URL(path, origin).pathname;
  } catch {
    return null;
  }
};
