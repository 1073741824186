import React from 'react';
import {
  Navigate, Outlet, useSearchParams,
} from 'react-router-dom';
import Loading from '../Loading';
import { sanitizeRedirectPath } from './utils';
import { EXTERNAL_PARTNER } from './constants';
import useSession from '../../resources/session/read';

const Checkpoint = ({ shouldBeAuthenticated }) => {
  const [searchParams] = useSearchParams();
  const redirectUri = searchParams.get('redirect_uri');
  const cleanedRedirectUri = sanitizeRedirectPath(redirectUri);
  const { data: session } = useSession();

  const notAuthenticatedButShouldBe = !session && shouldBeAuthenticated;

  // CASE: User just logged out and has a redirect_uri
  //       Send them to the redirect_uri
  if (notAuthenticatedButShouldBe && cleanedRedirectUri) {
    window.location.replace(cleanedRedirectUri);
    return <Loading />;
  }

  // CASE: User just logged out and does not have a redirect_uri
  //       Send them to login
  if (notAuthenticatedButShouldBe) {
    return <Navigate to="/" replace />;
  }

  // CASE: User just logged in
  //       Redirect them to an appropriate location
  if (session && !shouldBeAuthenticated) {
    if (session.role === EXTERNAL_PARTNER) {
      window.location.replace(`/${process.env.PREFIX}/partners`);
    } else if (session.servicingPlatform === 'PEACH') {
      window.location.replace(process.env.PEACH_AUTH_URL);
    } else if (cleanedRedirectUri) {
      window.location.replace(cleanedRedirectUri);
    } else {
      window.location.replace('/home');
    }

    return <Loading />;
  }

  return <Outlet />;
};

export default Checkpoint;
