import { refreshToken } from './tokenRefreshApi';

let refreshing = false;

const interceptor = async (error) => {
  if (error?.response?.status !== 401 || refreshing) {
    return Promise.reject(error);
  }
  refreshing = true;

  try {
    await refreshToken();
    window.location.reload();
  } catch (err) {
    window.location.replace(`/${process.env.PREFIX}/auth?redirect_uri=${window.location}`);
  }
  return Promise.reject(error);
};

export default interceptor;
