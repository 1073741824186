import React from 'react';
import { Link } from 'react-router-dom';
import MutatePassword from '../SetPassword';

const CreatePassword = () => (
  <MutatePassword
    documentTitle="Create Account"
    title="Create an account"
    subtitle="Welcome to DigniFi! Enter a password below to create your account."
    successText="Your account has been created."
    invalidTokenMessage="The account creation email we sent has expired. Please contact support."
    submitText="Create account"
    signInText={(
      <span className="centered">
        Already a member?
        {' '}
        <Link to="/">
          Sign In
        </Link>
      </span>
      )}
  />
);
export default CreatePassword;
