import { preload } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { KEY } from '../constants';
import baseFetcher from '../../shared/fetcher';

const fetcher = async () => {
  try {
    return await baseFetcher({ url: '/users/me' });
  } catch (e) {
    if (e?.response?.status === 401) {
      return null;
    }
    throw e;
  }
};

const useSession = () => useSWRImmutable(KEY, fetcher, { suspense: true });

preload(KEY, fetcher);

export default useSession;
