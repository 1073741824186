import axios from 'axios';
import tokenRefreshInterceptor from './tokenRefreshInterceptor';

const client = axios.create({
  baseURL: '/platform',
});

client.interceptors.response.use(
  (response) => response,
  tokenRefreshInterceptor,
);
export default client;
