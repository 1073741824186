import useSWRMutation from 'swr/mutation';
import baseFetcher from '../../shared/fetcher';
import { KEY } from '../constants';

const fetcher = (_, { arg: data }) => baseFetcher({
  method: 'POST',
  url: '/authentication/reset-password',
  data,
});

const useResetPassword = (options) => (
  useSWRMutation(KEY, fetcher, { revalidate: false, ...options })
);

export default useResetPassword;
