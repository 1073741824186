/* istanbul ignore file */
import { createTheme } from '@mui/material/styles';

const muiTheme = createTheme({
  palette: {
    primary: {
      light: '#e0f3f8',
      main: '#046B99',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#E35751',
      main: '#AB2328',
      dark: '#750000',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#757677',
    },
    success: {
      main: '#018301',
    },
    error: {
      main: '#EE0014',
    },
    warning: {
      main: '#A85700',
      contrastText: '#FFFFFF',
    },
    text: {
      main: '#383838',
    },
  },
  typography: {
    fontFamily: 'Montserrat, Helvetica, sans-serif',
    h1: {
      fontSize: '34px',
      lineHeight: '40px',
      fontWeight: 700,
    },
    h2: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 700,
    },
    h3: {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 700,
    },
    h4: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 700,
    },
    body1: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    body2: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
    },
    caption: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 500,
      color: '#757677',
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        standardInfo: ({ theme }) => ({
          backgroundColor: '#E2EEF3',
          color: '#042635',
          '.MuiAlert-icon': {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        size: 'large',
        color: 'secondary',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: '30px',
          textTransform: 'none',
          fontWeight: 700,
          '&.Mui-disabled': {
            color: theme.palette.info.main,
          },
        }),
        sizeLarge: {
          fontSize: '16px',
          lineHeight: '24px',
          padding: '12px 24px',
        },
        sizeMedium: {
          fontSize: '14px',
          lineHeight: '24px',
          padding: '8px 16px',
        },
        sizeSmall: {
          fontSize: '14px',
          lineHeight: '20px',
          padding: '6px 12px',
        },
        contained: {
          '&.Mui-disabled': {
            backgroundColor: '#EBEBEB',
          },
        },
        text: ({ theme }) => ({
          color: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#BBBFC3',
          backgroundColor: '#FAFCFC',
          padding: 0,
          height: '18px',
          width: '18px',
          '&:hover': {
            backgroundColor: '#f5f8fa',
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: '#F5F5F5;',
          height: '55px',
        },
        columnHeaderTitle: {
          fontWeight: '600',
          lineHeight: 1,
          whiteSpace: 'normal',
          textAlign: 'right',
        },
        root: {
          border: '0px',
          '& .MuiButton-root': {
            background: 'transparent',
          },
          '& .MuiButton-root:hover': {
            background: 'transparent',
          },
        },
        main: {
          borderRadius: '8px',
          backgroundColor: '#FFFFFF',
          border: '1px solid rgba(224, 224, 224, 1)',
        },
        footerContainer: {
          borderTop: '0px',
          '& .MuiIconButton-root': {
            color: 'rgba(0, 0, 0, 0.54)',
          },
        },
        panel: ({ theme }) => ({
          '& .MuiButton-root': {
            background: 'transparent',
            color: theme.palette.primary.main,
            boxShadow: 'none',
          },
          '& .MuiButton-root:hover': {
            background: 'transparent',
            boxShadow: 'none',
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          borderRadius: '4px',
          backgroundColor: '#FFFFFF',
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.info.main,
          '&.Mui-error': {
            color: theme.palette.error.main,
          },
          '&.Mui-disabled': {
            color: theme.palette.info.main,
          },
        }),
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          paddingLeft: '10px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:hover': {
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 2px 8px rgba(4, 107, 153, 0.25)',
          },
          '&:hover fieldset': {
            borderColor: `${theme.palette.primary.main} !important`,
          },
          '&.Mui-error fieldset': {
            borderColor: `${theme.palette.error.main} !important`,
          },
          '&.Mui-disabled fieldset': {
            borderColor: `${theme.palette.info.main} !important`,
          },
          '&.Mui-disabled': {
            backgroundColor: '#EBEBEB',
            boxShadow: 'none !important',
          },
        }
        ),
        notchedOutline: ({ theme }) => ({
          borderColor: theme.palette.info.main,
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#383838',
          lineHeight: '14px',
          fontSize: '10px',
        },
        arrow: {
          color: '#383838',
        },
      },
    },
  },
});

export default muiTheme;
