import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Button, CircularProgress, TextField } from '@mui/material';
import ValidatedTextField from 'common-mui/components/ValidatedTextField';
import Password from 'common-mui/components/Password';
import { EMAIL_REGEX } from 'common-constants/validation';
import useSWRMutationForm from 'common-form/useSWRMutationForm';
import Form from '../Form';
import styles from './styles.css';
import useTitle from '../../utils/useTitle';
import useLogin from '../../resources/session/login';

const ACCOUNT_DEACTIVATED = 'ACCOUNT_DEACTIVATED';
const UNABLE_TO_AUTHENTICATE_USER = 'UNABLE_TO_AUTHENTICATE_USER';
const PASSWORD_RESET_REQUIRED = 'PASSWORD_RESET_REQUIRED';

const errorSchema = {
  [ACCOUNT_DEACTIVATED]: {},
  [PASSWORD_RESET_REQUIRED]: {},
  [UNABLE_TO_AUTHENTICATE_USER]: {
    email: [''],
    password: [''],
  },
};

const errorMessage = {
  [ACCOUNT_DEACTIVATED]: 'Your DigniFi account has been deactivated.',
  [PASSWORD_RESET_REQUIRED]: 'Your password has expired. We sent you an email to reset your password. Please reset your password to access your account.',
  [UNABLE_TO_AUTHENTICATE_USER]: 'Invalid email address or password.',
};

const Login = () => {
  useTitle('Sign In');
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email') || '';

  const {
    isSubmitting,
    handleSubmit,
    error,
    control,
  } = useSWRMutationForm({
    useMutation: useLogin,
    errorSchema,
  });

  return (
    <Form
      title="Sign in"
      subtitle="Manage your account and make payments."
      error={errorMessage[error]}
      onSubmit={handleSubmit}
    >
      <ValidatedTextField
        name="email"
        control={control}
        defaultValue={email}
        rules={{
          required: 'Email is required.',
          pattern: {
            value: EMAIL_REGEX,
            message: 'Please check that your email is in the format: name@domain.com.',
          },
        }}
      >
        <TextField
          fullWidth
          label="Email"
        />
      </ValidatedTextField>
      <ValidatedTextField
        name="password"
        control={control}
        rules={{
          required: 'Password is required.',
        }}
      >
        <Password />
      </ValidatedTextField>
      <Button
        fullWidth
        type="submit"
        disabled={isSubmitting}
        startIcon={isSubmitting && <CircularProgress size={18} sx={{ color: '#FFFFFF' }} />}
      >
        Sign in
      </Button>
      <Link
        className="centered"
        to="/forgot"
      >
        Forgot your password?
      </Link>
      <div className={styles.divider} />
      <p className="centered">
        New to DigniFi?
        {' '}
        <a href={`/${process.env.PREFIX}/prequal`}>Get Prequalified</a>
      </p>
    </Form>
  );
};

export default Login;
