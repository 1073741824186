import { Route, Routes } from 'react-router-dom';
import React from 'react';
import Login from '../Login';
import Forgot from '../Forgot';
import ResetPassword from '../ResetPassword';
import CreatePassword from '../CreatePassword';
import Logout from '../Logout';
import Checkpoint from '../Checkpoint';
import Layout from '../Layout';
import CreatePasswordExternal from '../CreatePasswordExternal';
import BareLayout from '../BareLayout';

const Router = () => (
  <Routes>
    <Route element={<Layout />}>
      <Route element={<Checkpoint shouldBeAuthenticated={false} />}>
        <Route index element={<Login />} />
        <Route path="forgot" element={<Forgot />} />
        <Route path="reset" element={<ResetPassword />} />
        <Route path="create" element={<CreatePassword />} />
      </Route>
      <Route element={<Checkpoint shouldBeAuthenticated />}>
        <Route path="logout" element={<Logout />} />
      </Route>
    </Route>
    <Route element={<BareLayout />}>
      <Route element={<Checkpoint shouldBeAuthenticated={false} />}>
        <Route path="create-external" element={<CreatePasswordExternal />} />
      </Route>
    </Route>
  </Routes>
);

export default Router;
