import React from 'react';
import { Link } from 'react-router-dom';
import MutatePassword from '../SetPassword';

const ResetPassword = () => (
  <MutatePassword
    documentTitle="Change Password"
    title="Change password"
    successText="Your account password has successfully been changed."
    invalidTokenMessage="The account recovery email we sent has expired. Please reset your password again."
    submitText="Change my password"
    signInText={(
      <Link className="centered" to="/">
        Return to Sign In
      </Link>
    )}
  />
);

export default ResetPassword;
